<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="savePackage"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <input v-model="packageObj.Name" class="input" />
      </div>

      <div class="box mt-5">
        <p class="subtitle">Base</p>

        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td>Activated</td>
              <td>
                <div class="tabs is-toggle is-small">
                  <ul>
                    <li :class="{ 'is-active': packageObj.IsActive }">
                      <a @click="packageObj.IsActive = true">
                        <span>Yes</span>
                      </a>
                    </li>
                    <li :class="{ 'is-active': !packageObj.IsActive }">
                      <a @click="packageObj.IsActive = false">
                        <span>No</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>Monthly fixed fee</td>
              <td>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button is-static">&euro;</a>
                  </p>
                  <p class="control">
                    <input
                      type="text"
                      v-model.number="packageObj.MonthPrice"
                      class="input"
                    />
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <div
          v-for="meetingtype in meetingtypes"
          :key="meetingtype.MeetingtypeId"
          class="box"
        >
          <nav class="navbar" role="navigation" aria-label="main navigation">
            <div id="navbarBasicExample" class="navbar-menu">
              <div class="navbar-start">
                <span class="has-text-weight-bold">{{
                  meetingtype.MeetingtypeId | getMeetingtypeName
                }}</span>
              </div>

              <div class="navbar-end">
                <div class="navbar-item">
                  <label class="checkbox">
                    <div class="field">
                      <input
                        :id="'mt_' + meetingtype.MeetingtypeId"
                        type="checkbox"
                        class="switch"
                        v-model="meetingtype.IsActive"
                        @change="meetingtypeClicked"
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </nav>

          <div v-if="meetingtype.IsActive">
            <table class="table is-fullwidth">
              <tbody>
                <tr>
                  <td>Fee</td>
                  <td class="has-text-right">
                    <div class="field has-addons">
                      <p class="control">
                        <a class="button is-static">&euro;</a>
                      </p>
                      <p class="control">
                        <input
                          type="text"
                          v-model.number="meetingtype.Fee"
                          class="input"
                        />
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="400">Max fee</td>
                  <td class="has-text-right">
                    <div class="field has-addons">
                      <p class="control">
                        <a class="button is-static">&euro;</a>
                      </p>
                      <p class="control">
                        <input
                          type="text"
                          v-model.number="meetingtype.MaxTotalFee"
                          class="input"
                        />
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Allow prices</td>
                  <td class="has-text-right">
                    <div class="tabs is-toggle is-small">
                      <ul>
                        <li :class="{ 'is-active': meetingtype.AllowPrices }">
                          <a @click="meetingtype.AllowPrices = true">
                            <span>Yes</span>
                          </a>
                        </li>
                        <li :class="{ 'is-active': !meetingtype.AllowPrices }">
                          <a @click="meetingtype.AllowPrices = false">
                            <span>No</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Check capacity</td>
                  <td class="has-text-right">
                    <div class="tabs is-toggle is-small">
                      <ul>
                        <li :class="{ 'is-active': meetingtype.CheckCapacity }">
                          <a @click="meetingtype.CheckCapacity = true">
                            <span>Yes</span>
                          </a>
                        </li>
                        <li
                          :class="{ 'is-active': !meetingtype.CheckCapacity }"
                        >
                          <a @click="meetingtype.CheckCapacity = false">
                            <span>No</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <div class="pt-3">
        <p class="subtitle">Backend limitations</p>

        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td width="400">Create backend reservation</td>
              <td>
                <input
                  type="checkbox"
                  v-model="packageObj.BackendCreateReservation"
                />
              </td>
            </tr>
            <tr>
              <td>Max. spaces</td>
              <td>
                <input
                  type="text"
                  class="input"
                  v-model="packageObj.MaxSpaces"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

      <div class="box">
        <p class="subtitle">Functionality bundle</p>

        <table class="table is-fullwidth">
          <tbody v-if="packageObj.Bundles && packageObj.Bundles.length > 0">
            <tr v-for="(bundle, index) in packageObj.Bundles" :key="index">
              <td>{{ bundle.Name }}</td>
              <td width="100" class="has-text-right">
                <a @click="deleteBundle(bundle)" class="has-text-danger ">
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </span>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div class="select">
                  <select @change="addBundle" v-model="newBundle">
                    <option :value="null">Select bundle</option>
                    <option
                      v-for="(bundle, index) in bundles"
                      :key="index"
                      :value="bundle"
                      >{{ bundle.Name }}</option
                    >
                  </select>
                </div>
              </td>
              <td width="100" class="has-text-right">
                <a @click="addBundle" class="has-icon">
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </span>
                  <span>Add</span>
                </a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      meetingtypes: [],
      newBundle: null,
      packageObj: {
        Name: '',
        Short: '',
        Description: '',
        IsActive: false,
        CountryId: 0,
        MonthPrice: 0,
        YearPrice: 0,
        SeatFeeMS: 0,
        MaxSeatFeeTotalMS: 0,
        SeatFeeWS: 0,
        MaxSeatFeeTotalWS: 0,
        NrOfFreeSeats: 0,
        BackendCreateReservation: true,
        MaxSpaces: 0,
        ChannelId: Number(this.$route.params.channelId),
        Bundles: [],
        Meetingtypes: [],
        CurrencyId: 46,
      },
    }
  },

  computed: {
    ...mapState('bundleStore', ['bundles']),
    ...mapState('channelStore', ['channel']),
  },

  created() {
    if (this.bundles.length === 0) {
      this.getAllBundles(this.channelId)
    }

    for (let i = 0; i < this.channel.Meetingtypes.length; i++) {
      let mt = this.channel.Meetingtypes[i]

      this.meetingtypes.push({
        IsActive: false,
        MeetingtypeId: mt.MeetingtypeId,
        AllowPrices: true,
        CheckCapacity: true,
        Fee: 0,
        MaxTotalFee: 0,
      })
    }
  },

  methods: {
    ...mapActions('bundleStore', ['getAllBundles']),
    ...mapActions('packageStore', ['createPackage']),

    addBundle() {
      if (this.newBundle) {
        this.packageObj.Bundles.push(this.newBundle)
        this.newBundle = null
      }
    },

    deleteBundle(bundle) {
      let index = this.packageObj.Bundles.findIndex((b) => b.Id === bundle.Id)
      if (index > -1) {
        this.packageObj.Bundles.splice(index, 1)
      }
    },

    meetingtypeClicked(meetingtype) {
      if (!meetingtype.IsActive) {
        meetingtype.Fee = 0
        meetingtype.MaxTotalFee = 0

        if (meetingtype.MeetingtypeId === 1) {
          self.packageObj.SeatFeeMS = 0
          self.packageObj.MaxSeatFeeTotalMS = 0
        }

        if (meetingtype.MeetingtypeId === 2) {
          self.packageObj.SeatFeeWS = 0
          self.packageObj.MaxSeatFeeTotalWS = 0
        }
      }
    },

    savePackage() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        let activeMeetingtypes = self.meetingtypes.filter((mt) => mt.IsActive)

        for (let i = 0; i < activeMeetingtypes.length; i++) {
          let mt = activeMeetingtypes[i]

          if (mt.MeetingtypeId === 1) {
            self.packageObj.SeatFeeMS = mt.Fee
            self.packageObj.MaxSeatFeeTotalMS = mt.MaxTotalFee
          }

          if (mt.MeetingtypeId === 2) {
            self.packageObj.SeatFeeWS = mt.Fee
            self.packageObj.MaxSeatFeeTotalWS = mt.MaxTotalFee
          }

          self.packageObj.Meetingtypes.push({
            MeetingtypeId: mt.MeetingtypeId,
            AllowPrices: mt.AllowPrices,
            CheckCapacity: mt.CheckCapacity,
          })
        }

        self
          .createPackage(self.packageObj)
          .then((response) => {
            if (response.status === 201) {
              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Package created!',
              })

              self.onClickCancel()
            }
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
